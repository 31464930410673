import React from "react"
import { graphql } from "gatsby"
import md5 from "blueimp-md5"

import ToolLayout from "../../../components/tool_layout"
import PipeWidget from "../../../components/widgets/pipe"

export const query = graphql`
  query MD5DecodeQuery {
    allToolsJson(filter: { path: { eq: "hash/md5" } }) {
      edges {
        node {
          path
          title
          description
          links {
            mdn
            github
          }
        }
      }
    }
  }
`

class MD5DecodePage extends React.Component<{ data: any }> {
  render() {
    const transformer = md5

    return (
      <ToolLayout data={this.props.data}>
        <PipeWidget
          transformer={transformer}
          exceptionHandler={e => {
            if (e) {
              return "Invalid input"
            }
          }}
          inputProps={{
            placeHolderText: "Input text here",
          }}
          outputProps={{
            placeHolderText: "Output will be shown here",
          }}
        />
      </ToolLayout>
    )
  }
}

export default MD5DecodePage
